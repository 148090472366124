// import i18n from '@/i18n/index'

export default {
  basicInformation: [{
    prop: 'online',
    back: true
  }, {
    prop: 'name'
  }, {
    prop: 'name_en'
  }, {
    prop: 'lon'
  }, {
    prop: 'lat'
  }, {
    prop: 'description',
    span: 24
  }, {
    prop: 'description_en',
    span: 24
  }, {
    prop: 'introduce',
    span: 24
  }, {
    prop: 'introduce_en',
    span: 24
  }, {
    prop: 'star_rate'
  }, {
    prop: 'book_type'
  }, {
    prop: 'rating'
  }, {
    prop: 'address'
  }, {
    prop: 'website'
  }, {
    prop: 'contact_number'
  }, {
    prop: 'email  '
  }, {
    prop: 'traffic_strategy',
    span: 24
  }, {
    prop: 'traffic_strategy_en',
    span: 24
  }, {
    prop: 'tips',
    span: 24
  }, {
    prop: 'tips_en',
    span: 24
  }, {
    prop: 'global_labels'
  }, {
    prop: 'redeem_code'
  }, {
    prop: 'updated_at'
  }, {
    prop: 'created_at'
  }]
}
